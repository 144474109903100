[v-cloak] {
  display: none;
}

.actions {
  padding: 3px !important;
  .btn {
    margin-bottom: 0px !important;
  }
}

.hr-line-dashed {
  margin: 10px 0;
}

.ibox-tools a.btn-primary.btn-outline {
  color: $navy;
}

.table .narrow {
  width: 1px;
}

.inline-block {
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
}

.borderless {
  border: 0 !important;
}

.text-nowrap {
	white-space: nowrap;
}

/* Make Select2 boxes match Bootstrap3 heights: */
.select2-selection__rendered {
  line-height: 26px !important;
}

.select2-selection {
  height: 30px !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 3px !important;
}

.panel-heading {
  transition: background-color 0.1s ease;

  .panel-title {
    margin-top: 0;
  }

  &:hover {
    background-color: #ececec;
    cursor: pointer;
  }
}

.nav-header {
  padding: 15px 25px 33px;
  font-size: 14px;
}