/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.6
 *
*/
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Variables, Mixins
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/datatables.net-bs/css/dataTables.bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/select2/dist/css/select2";
@import "node_modules/toastr/build/toastr";
@import "node_modules/ladda/dist/ladda-themeless.min";
@import "node_modules/nouislider/distribute/nouislider";

// Using older "inspinia version" of AnimateCSS because of animation distance change. At some point the animation
// was changed so for example fadeInRight uses entire page width. Older version uses maybe 20px so its much
// smoother at least for our purposes.
@import "../css/plugins/animate";

@import "inspinia/variables";
@import "inspinia/mixins";

// INSPINIA Theme Elements
@import "inspinia/typography";
@import "inspinia/navigation";
@import "inspinia/top_navigation";
@import "inspinia/buttons";
@import "inspinia/badgets_labels";
@import "inspinia/elements";
@import "inspinia/sidebar";
@import "inspinia/base";
@import "inspinia/pages";
@import "inspinia/chat";
@import "inspinia/metismenu";
@import "inspinia/spinners";

// Landing page styles
@import "inspinia/landing";

// RTL Support
@import "inspinia/rtl";

// For demo only - config box style
@import "inspinia/theme-config";

// INSPINIA Skins
@import "inspinia/skins";
@import "inspinia/md-skin";

// Media query style
@import "inspinia/media";

// Custom style
// Your custom style to override base style
@import "inspinia/custom";









